body {
  margin: 0;
  font-family: "Readex Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  color: aliceblue;
  transition: 0.2s linear;
  transition-property: background-color, border;
  border-radius: 4.625px;
  border: 1px solid transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  width: 100%;
  align-items: center;
  padding: 10px 12px;
  display: flex;
  justify-content: center;
  outline: none;
  cursor: pointer;
  background-color: #4284fb;
  border-color: #4284fb;
}

button:hover {
  background-color: #376ed3;
  border-color: #376ed3;
}

.not-found-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.not-found-container {
  text-align: center;
  background: #e6effe;
  padding: 30px 10px;
  border-radius: 8px;
  margin: 0 24px;
  line-height: 21px;
  width: 100%;
  max-width: 400px;
  color: #0c1c2b;
}

.not-found-wrapper .not-found-heading {
  font-size: 18px;
}

ff-onboarding-widget {
  flex: 1;
}

html,
body,
#root {
  height: 100%;
}

.averer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.support-button {
  padding: 18px 24px;
  border-radius: 8px;
  border: 0px;
  font-family: "Space Mono", monospace !important;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  width: 186px;
  height: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  outline: none;
  cursor: pointer;
  background-color: #217aac;
}

.averer-header {
  display: flex;
  justify-content: space-between;
  padding: 32px 20px;
  border: 1px solid #bfbfbf;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  line-height: 2;
}

.averer-header > div > h2 {
  margin: 0px;
  font-family: "Space Mono", monospace !important;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #0d0d0d;
}

.averer-header > div > h2 > span {
  color: #293498;
  font-family: "Space Mono", monospace !important;
}

.animated-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media screen and (max-width: 568px) {
  .averer-header {
    padding: 20px 20px !important;
  }

  .averer-header > div > h2 {
    font-size: 20px !important;
  }

  .averer-header > div > img {
    width: 90px !important;
  }
}
