.landing-modal {
  margin: auto;
  position: absolute;
  top: 50px !important;
  left: 50px !important;
  bottom: 50px !important;
  right: 50px !important;
  color: black;
  z-index: 2 !important ;
  background-color: #ece7e7 !important;
}

.landing-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center ;
}
.landing-modal h1{
  flex:0.5
}

.edit-results{
  position: relative;
  display: flex;
  flex-direction: row;
  
  scroll-behavior: smooth;
  background: #e7e1e1;
  overflow: scroll;
}

.edit-results div{
  padding:10px;
}

.verified{
  color: green
}

  .failed{
  color:red
}